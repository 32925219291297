<template>
  <div class="footer">
    <img width="100%" :src="require('@/assets/images/footer.png')" alt="">
    <!-- <div class="footer-box">
      <div class="footer-title-box">
        <div class="footer-title">联系我们</div>
        <div class="footer-subtitle">Contact us</div>
      </div>
      <div class="footer-content">
        <div class="footer-content-box">
          <p>客服电话：400***2586</p>
          <p>QQ号：265****208</p>
          <p>通讯地址：北京市朝阳区XXX路888号有座大厦##室</p>
        </div>
        <div class="footer-content-box">
          <p>业务咨询邮箱：888xxxx@qq.com</p>
          <p>售后服务邮箱：888xxxx@qq.com</p>
          <p>业务受理邮箱：888xxxx@qq.com</p>
        </div>
        <div class="footer-content-box footer-weixin">
          <img width="100%" :src="require('@/assets/images/icon-1.png')" alt="">
          <div>微信公众号</div>
        </div>
      </div>
    </div>
    <div class="foot">北京某某科技有限公司 ©2015-2016版权所有。京ICP备15027356号。</div> -->
  </div>
</template>

<script>
export default {
  name: "Foot",
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style scoped>
/* .footer {
  position: relative;
  height: 480px;
  background: url('../assets/images/footer.png') 100% 100% no-repeat;
} */

.footer .foot {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.3);
}

.footer-box {
  width: 1200px;
  margin: 0 auto;
  color: #fff;
}

.footer-title-box {
  padding: 40px 0;
}

.footer-title {
  width: 120px;
  font-size: 30px;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.footer-content-box {
  width: 320px;
  height: 100px;
  padding-right: 50px;
  margin-right: 50px;
  /* padding-top:20px; */
  line-height: 180%;
  box-sizing: border-box;
  border-right: 1px solid #333;
  float: left;
  font-size: 14px;
}

.footer-content-box:last-child {
  border: none;
}

.footer-weixin {
  text-align: center;
}

.footer-weixin img {
  width: 120px;
  height: 120px;
}
</style>