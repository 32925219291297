<template>
  <div class="news">

    <Head />
    <div class="box">
      <div style="text-align: center;font-size:30px;margin-bottom:50px;">{{ title }}老照片</div>
      <div class="left">
        <div class="item" v-for="(item, index) in filteredLeft" :key="index">
          <el-image
            fit="cover"
            :src="item.image" 
            :preview-src-list="images">
          </el-image>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
      <!-- <div class="center">
        <div class="item" v-for="(item, index) in filteredCenter" :key="index">
          <el-image
            fit="cover"
            :src="item.url" 
            :preview-src-list="images">
          </el-image>
          <div class="title">{{ item.name }}</div>
        </div>
      </div> -->
      <div class="right">
        <div class="item" v-for="(item, index) in filteredRight" :key="index">
          <el-image
            fit="cover"
            :src="item.image" 
            :preview-src-list="images">
          </el-image>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: "Detail",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      list: [],
      images: [],
      title: ''
    };
  },
  computed: {
    filteredLeft() {
      return this.list.filter(function (item, index) {
        console.log(item)
        return index % 2 == 0
      })
    },
    // filteredCenter() {
    //   return this.list.filter(function (item, index) {
    //     console.log(item)
    //     return index % 3 == 1
    //   })
    // },
    filteredRight() {
      return this.list.filter(function (item, index) {
        console.log(item)
        return index % 2 == 1
      })
    }
  },
  mounted() {
    this.getData()
    switch (this.$route.params.id) {
      case '26':
        this.title = '70年代'
        break;
      case '27':
        this.title = '80年代'
        break;
      case '28':
        this.title = '90年代'
        break;
      case '29':
        this.title = '21世纪'
        break;
      default:
        this.title = '50~60年代'
        break;
    }
  },
  methods: {
    getData() {
      let that = this
      that.$api.get('/index/getArchivesList', {
        params: {
          channel_id: that.$route.params.id,
          page: 1,
          page_size: 999,
        }
      }).then(function (response) {
        that.list = response.data.data.lists
        that.images = response.data.data.images
      });
    },
  }
};
</script>

<style scoped>
  .box{
    width:1000px;
    margin:30px auto;
  }
  .box img{
    width:100%;
    display:block;
  }
  .item{
    margin-bottom:50px;
    text-align: center;
  }
  .title{
    font-size:24px;
    margin-top:5px;
  }
  .left{
    width:50%;
    float:left;
    padding-right:15px;
    box-sizing: border-box;
  }
  .center{
    width:33.3%;
    float:left;
    box-sizing: border-box;
  }
  .right{
    width:50%;
    float:right;
    padding-left:15px;
    box-sizing: border-box;
  }
</style>