import axios from 'axios'

let cancel
const pending = {}

// const baseURL = 'http://lyt.gxzmhd.com/api/'
const baseURL = '/api'

const instance = axios.create({
  baseURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 发起请求时，取消掉当前正在进行的相同请求
    if (pending[config.url]) {
      pending[config.url] = cancel
    } else {
      pending[config.url] = cancel
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

export default instance
