<template>
  <div class="news">

    <Head />
    <div class="box">
      <div class="title">{{ content.title }}</div>
      <div class="time">{{ content.publishtime_text }}</div>
      <div class="content" v-html="content.content"></div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: "Detail",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      content: [],
    };
  },
  mounted() {
    this.getNewsData()
  },
  methods: {
    // 获取新闻
    getNewsData() {
      let that = this
      that.$api.get('/index/getArchivesDetail', {
        params: {
          id: that.$route.params.id,
        }
      }).then(function (response) {
        that.content = response.data.data
      }).catch(function (error) {
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
  .box{
    width:1200px;
    margin:50px auto;
  }
  .title{
    width:70%;
    margin:0 auto;
    font-size:24px;
    text-align: center;
    color:#333;
    line-height:180%;
  }
  .time{
    font-size:16px;
    text-align: center;
    color:#666;
    margin-top:20px;
  }
  .content{
    margin-top:50px;
    line-height:200%;
  }
  >>> .content img{width:600px;}
</style>