<template>
  <div class="line">
    <div class="home">
      <div class="logo" @click="$router.push('/')">
        <img :src="require('@/assets/images/logo.jpg')" alt="">
      </div>
      <!-- <div class="nav">
        <ul>
          <li :class="$route.name == 'Home' ? 'on' : ''" @click="$router.push('/')">首页</li>
          <li :class="$route.name == 'News' && $route.params.id==6 ? 'on' : ''" @click="$router.push('/news/' + 6)">70年回顾</li>
          <li :class="$route.name == 'News' && $route.params.id==5 ? 'on' : ''" @click="$router.push('/news/' + 5)">行业动态</li>
          <li :class="$route.name == 'News' && $route.params.id==4 ? 'on' : ''" @click="$router.push('/news/' + 4)">院内新闻</li>
          <li :class="$route.name == 'News' && $route.params.id==3 ? 'on' : ''" @click="$router.push('/news/' + 3)">林勘回顾</li>
          <li :class="$route.name == 'News' && $route.params.id==24 ? 'on' : ''" @click="$router.push('/news/' + 24)">相关业务</li>
        </ul>
      </div> -->
      <div v-if="$route.name == 'Home'">
        <div class="search">
          <input type="text" v-model="keyword" placeholder="搜索...">
          <div class="button" style="float:right;" @click="handleSearch"><img :src="require('@/assets/images/search.png')" alt=""></div>
        </div>
        <div class="time">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Head",
  data() {
    return {
      time: '',
      keyword: '',
    };
  },
  mounted() {
    this.time = new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日 ' + "星期" + "日一二三四五六".charAt(new Date().getDay());
  },
  methods: {
    handleSearch(){
      if(this.keyword==''){
        this.$message.error('搜索关键字不能为空');
        return false;
      }
      this.$router.push('/search/' + this.keyword);
    }
  }
};
</script>

<style scoped>
.line {
  width: 100%;
  border-bottom: 1px solid#1d9c7b;
}

.home {
  width: 1200px;
  margin: 0 auto;
  height: 120px;
  display: flex;
  position: relative;
}

.search {
  position: absolute;
  right: 35px;
  bottom: -70px;
  z-index: 99;
  width: 370px;
  height: 40px;
  border-radius: 3px;
  background: #fff;
}

.time {
  position: absolute;
  right: 35px;
  bottom: -110px;
  z-index: 99;
  color: #ffffff;
  font-size: 18px;
}

.search input {
  width: 300px;
  height: 38px;
  border: none;
  outline: none;
  margin-left: 10px;
}

.search .button {
  float: right;
  background: #1d9c7b;
  width: 60px;
  height: 40px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.search input:focus {
  border: none;
  outline: none;
}

.logo img {
  height:80px;
  padding-top: 20px;
  cursor: pointer;
}

.nav {
  padding-top: 70px;
  margin-left: 50px;
}

.nav ul li {
  font-size: 20px;
  display: inline-block;
  color: #666666;
  padding: 0 5px 10px 5px;
  margin: 0 15px;
  cursor: pointer;
}

.nav ul .on,
.nav ul li:hover {
  color: #1d9c7b;
  border-bottom: 2px solid #1d9c7b;
}</style>