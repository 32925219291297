import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import News from '../views/News.vue'
import Search from '../views/Search.vue'
import Detail from '../views/Detail.vue'
import Images from '../views/Images.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news/:id(\\d+)',
    name: 'News',
    component: News
  },
  {
    path: '/search/:keyword',
    name: 'Search',
    component: Search
  },
  {
    path: '/detail/:id(\\d+)',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/images/:id',
    name: 'Images',
    component: Images
  },
]

// const router = new VueRouter({routes})
const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    if (savedPosition) {
      // hack 滚动到保存的位置， 原生方法失效的问题
      setTimeout(() => {
        window.scrollTo(savedPosition.x, savedPosition.y)
      }, 100)
    } else {
      const {
        meta: { keepAlive = false, toTop = false },
      } = from
      if (keepAlive && !toTop) {
        from.meta.savedPosition =
          document.body.scrollTop || document.documentElement.scrollTop
      }
      setTimeout(() => {
        window.scrollTo(0, to.meta.savedPosition)
      }, 100)
    }
  },
})

export default router
