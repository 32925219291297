<template>
  <div class="news">

    <Head />
    <div class="search">
      <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select">
        <el-button slot="append" @click="handleSearch" icon="el-icon-search"></el-button>
      </el-input>
      <div class="result">搜索结果：{{ total }} 条</div>
    </div>
    <div class="list">
      <div class="list-item" v-for="(item, index) in lists" :key="index" @click="goDetail(item)">
        <img width="100%" :src="item.image" alt="">
        <div class="box">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.description }}</div>
        </div>
        <span class="time">{{ item.createtime }}</span>
      </div>
    </div>
    <div style="text-align: center;margin-bottom:50px;" v-if="lists.length>0">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size"
        @current-change="handleCurrentChange"></el-pagination>
    </div>
    <div class="no-data" v-if="lists.length==0">暂无数据</div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: "Search",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      page: 1,
      page_size: 5,
      lists: [],
      total: 0,
      keyword: '',
    };
  },
  mounted() {
    this.keyword = this.$route.params.keyword;
    this.getNewsList()
  },
  methods: {
    goDetail(item){
      if(item.outlink==''){
        this.$router.push('/detail/' + item.id)
      }else{
        window.open(item.outlink, "_blank")
      }
    },
    handleSearch(){
      if(this.keyword==''){
        this.$message.error('搜索关键字不能为空');
        return false;
      }
      this.getNewsList();
    },
    // 处理改变当前页数
    handleCurrentChange(val) {
      this.page = val;
      this.getNewsList();
    },
    // 获取新闻
    getNewsList() {
      let that = this
      that.$api.get('/index/getArchivesList', {
        params: {
          page: that.page,
          page_size: that.page_size,
          keyword: that.keyword,
        }
      }).then(function (response) {
        that.lists = response.data.data.lists
        that.total = response.data.data.total
      }).catch(function (error) {
        console.log(error);
      });
    },
  }
};
</script>

<style scoped>
.no-data{
  width:1200px;
  margin:150px auto;
  text-align: center;
}
.search{
  width:1200px;
  margin:20px auto;
}
.result{
  margin-top:5px;
  font-size:14px;
  color:#1d9c7b;
}
.list {
  width: 1200px;
  margin: 50px auto;
}

.list-item {
  height: 160px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
}

.list-item img {
  width: 140px;
  height: 140px;
  position: absolute;
  left: 0;
  top: 0;
}

.box {
  margin-left: 160px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.desc {
  font-size: 14px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 150%;
}

.time {
  position: absolute;
  right: 0;
  bottom: 20px;
  color: #666;
  font-size: 14px;
}
</style>