<template>
  <div class="home">
    <Head />
    <div class="banner">
      <img width="100%" :src="require('@/assets/images/banner.jpg')" alt="">
    </div>
    <div class="main">
      <div class="box-11">
        <div class="box box-1">
          <div class="box-title-box">
            <div class="box-title">70年回顾</div>
            <div class="box-title-desc">70 YEAR REVIEW</div>
          </div>
          <div class="box-content" style="margin-top:40px;">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in swipe" :key="index">
                  <videoPlayer
                    class="vjs-custom-skin videoPlayer"
                    ref="videoplayer"
                    :playsinline="true"
                    :options="playerOptions[index]"
                    customEventName="changed"
                  >
                  </videoPlayer>
                </div>
              </div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="images-box" style="margin-top:30px;">
            <!-- <div>
              <img @click="$router.push('/images/56')" class="images-item" :src="require('@/assets/images/56.jpg')" alt="">
              <div>50、60年代老照片</div>
            </div> -->
            <img @click="$router.push('/images/25')" class="images-item" :src="require('@/assets/images/56.jpg')" alt="">
            <img @click="$router.push('/images/26')" class="images-item" :src="require('@/assets/images/70.jpg')" alt="">
            <img @click="$router.push('/images/27')" class="images-item" :src="require('@/assets/images/80.jpg')" alt="">
            <img @click="$router.push('/images/28')" class="images-item" :src="require('@/assets/images/90.jpg')" alt="">
            <img @click="$router.push('/images/29')" class="images-item" :src="require('@/assets/images/21.jpg')" alt="">
          </div>
        </div>
      </div>
      <div class="box-3" style="background: #f2f2f2;">
        <div class="box-title-box">
          <div class="box-title">院庆动态</div>
          <div class="box-title-desc">ACADEMY CELEBRATION NEWS</div>
        </div>
        <div class="box-list">
          <div class="box-list-item" v-for="(item, index) in yqdt" :key="index" @click="goDetail(item)">
            <img width="100%" :src="item.image" alt="">
            <div class="box-list-item-title">{{ item.title }}</div>
            <span class="box-list-item-more">详情>></span>
          </div>
          <div class="more" @click="$router.push('/news/' + 5)">浏览更多...</div>
        </div>
      </div>
      <div class="box-4">
        <div class="box-title-box">
          <div class="box-title">媒体报道</div>
          <div class="box-title-desc">MEDIA COVERAGE</div>
        </div>
        <div class="box-list">
          <div class="box-list-item" v-for="(item, index) in lkhg" :key="index" @click="goDetail(item)">
            <img width="100%" :src="item.image" alt="">
            <div class="box-list-item-title">{{ item.title }}</div>
            <div class="box-list-item-desc">{{ item.description }}</div>
            <span class="box-list-item-more">详情>></span>
          </div>
          <div class="more" @click="$router.push('/news/' + 4)">浏览更多...</div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import wx from 'weixin-js-sdk';

import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
  name: "Home",
  components: {
    Head,
    Foot,
    videoPlayer,
  },
  data() {
    return {
      swipe: [],
      lkhg: [],
      yqdt: [],
      playerOptions: [],
    };
  },
  mounted() {
    this.getSwipeLists()
    this.getNewsListLkhg()
    this.getNewsListYqdt()
    // 初始化分享配置
    this.initShareConfig()
  },
  methods: {
    initShareConfig() {
      const actions = ['openLocation', 'getLocation', 'updateAppMessageShareData', 'updateTimelineShareData', 'chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData', 'downloadImage', 'closeWindow', 'hideMenuItems', 'scanQRCode', '', '', 'showMenuItems', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'chooseWXPay']
      let that = this
      let url = window.location.href.split('#')[0]
      that.$api.get('/index/sign_pc', {
        api: actions,
        url: url
      }).then(function (res) {
        console.log(res)
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: res.data.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature,// 必填，签名
          jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的 JS 接口列表
        });
        that.initshareData();
      });
    },
    initshareData() {
      var link = 'http://lyt.gxzmhd.com/pc'
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '踔厉奋发林海梦 勇毅前行林勘人——广西壮族自治区林业勘测设计院成立70周年', // 分享标题
          desc: '广西壮族自治区林业勘测设计院成立70周年', // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://lyt.gxzmhd.com/pc/logo.png', // 分享图标
          success: function () {
            // 设置成功
            // alert('设置成功');
          }
        })
      });
      wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
        wx.updateTimelineShareData({
          title: '踔厉奋发林海梦 勇毅前行林勘人——广西壮族自治区林业勘测设计院成立70周年', // 分享标题
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://lyt.gxzmhd.com/pc/logo.png', // 分享图标
          success: function () {
            // 设置成功
            // alert('设置成功');
          }
        })
      });
    },
    goDetail(item){
      if(item.outlink==''){
        this.$router.push('/detail/' + item.id)
      }else{
        window.open(item.outlink, "_blank")
      }
    },
    initSwiper(){
      new Swiper('.swiper-container', {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // loop: true,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false
        // },
      })
    },
    // 获取新闻
    getSwipeLists() {
      let that = this
      that.$api.get('/index/getSwipeLists', {
        params: {
          name: 'videofocus',
        }
      }).then(function (response) {
        that.swipe = response.data.data
        for (let index = 0; index < that.swipe.length; index++) {
          that.playerOptions.push({
            fluid: true,
            language: "zh-CN",
            poster: that.swipe[index].image,
            sources: [
              {
                type: "video/mp4",
                src: that.swipe[index].url
              },
            ],
          })
        }
        setTimeout(() => {
          that.initSwiper()
        }, 500);
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 获取新闻
    getNewsListLkhg() {
      let that = this
      that.$api.get('/index/getArchivesList', {
        params: {
          page: that.page,
          page_size: 8,
          channel_id: 4,
        }
      }).then(function (response) {
        that.lkhg = response.data.data.lists
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 获取新闻
    getNewsListYqdt() {
      let that = this
      that.$api.get('/index/getArchivesList', {
        params: {
          page: that.page,
          page_size: 6,
          channel_id: 5,
        }
      }).then(function (response) {
        that.yqdt = response.data.data.lists
      }).catch(function (error) {
        console.log(error);
      });
    },
  }
};
</script>

<style>
.video-js .vjs-big-play-button{
  width:100px;
  height:50px;
  top:calc(50% - 25px);
  left:calc(50% - 50px);
}
.box-1 .swiper-pagination .swiper-pagination-bullet {
  background: #949494;
  width: 75px;
  height: 12px;
  border-radius: 10px;
  opacity: 1;
}

.box-1 .swiper-pagination .swiper-pagination-bullet-active {
  background: #1d9c7b;
}

.box-1 .swiper-button-prev:after,
.box-1 .swiper-button-next:after {
  color: #b6b9b4;
  font-size: 80px;
}

.box-1 .swiper-button-next,
.box-1 .swiper-container-rtl .swiper-button-prev {
  right: 30px;
  top:47%;
}

.box-1 .swiper-button-prev,
.box-1 .swiper-container-rtl .swiper-button-next {
  left: 30px;
  top:47%;
}
</style>

<style scoped>
.box-11{
  background: url('../assets/images/bg2.png') no-repeat;
  background-size:100% 100%;
  padding-top:80px;
  height:1100px;
}
.banner {
  position: relative;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.box-title-box {
  text-align: center;
}

.box-title {
  width: 292px;
  height: 73px;
  margin: 0 auto;
  background: url('../assets/images/title-bg.png') center no-repeat;
  text-align: center;
  line-height: 73px;
  font-size: 40px;
  color: #fff;
}

.box-title-desc {
  font-size: 20px;
  color: #666;
}

.box-1 .swiper-slide {
  height: 720px;
  position: relative;
}
.box-1 .swiper-title {
  width:100%;
  position: absolute;
  bottom:45px;
  left:0;
  height:90px;
  line-height:90px;
  text-align: center;
  color:#fff;
  font-size: 24px;
  background: rgba(0, 0, 0, 0.3);
}

.box-1 .swiper-slide img {
  height: 675px;
}
.box-3{
  padding:80px 0 40px 0;
}
.box-3 .box-list{
  width: 1200px;
  margin: 30px auto;
}
.box-3 .box-list-item{
  width:370px;
  height:380px;
  display:inline-block;
  margin:15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  padding:20px;
  position: relative;
  -moz-box-shadow: 0px 0px 20px #e3e3e3;
  -webkit-box-shadow: 0px 0px 20px #e3e3e3;
  box-shadow: 0px 0px 20px #e3e3e3;
  cursor: pointer;
}
.box-3 .box-list-item img{
  width:330px;
  height:230px;
  border-radius: 20px;
}
.box-3 .box-list-item .box-list-item-title{
  color:#010101;
  font-size:20px;
  line-height:150%;
  margin-top:10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box-3 .box-list-item .box-list-item-more{
  color:#999999;
  font-size:16px;
  position: absolute;
  bottom:20px;
  right:20px;
}
.box-3 .box-list .more {
  width: 200px;
  height: 50px;
  line-height: 50px;
  background: #1d9c7b;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: 30px auto;
  cursor: pointer;
}
.box-4{
  padding:80px 0 40px 0;
  background: url('../assets/images/bg1.png') 100% 100% no-repeat;
}
.box-4 .box-list{
  width: 1200px;
  margin: 30px auto;
}
.box-4 .box-list-item{
  width:270px;
  height:340px;
  display:inline-block;
  margin:15px;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  cursor: pointer;
  border: 1px solid #efefef;
}
.box-4 .box-list-item img{
  width:270px;
  height:200px;
}
.box-4 .box-list-item .box-list-item-title{
  color:#1d9c7b;
  font-size:20px;
  line-height:150%;
  margin:10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.box-4 .box-list-item .box-list-item-desc{
  color:#666666;
  font-size:14px;
  margin:10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box-4 .box-list-item .box-list-item-more{
  color:#999999;
  font-size:12px;
  position: absolute;
  bottom:10px;
  right:10px;
  border-bottom:3px solid #1d9c7b;
  padding-bottom:5px;
}
.box-4 .box-list .more {
  width: 200px;
  height: 50px;
  line-height: 50px;
  background: #1d9c7b;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: 30px auto;
  cursor: pointer;
}
.images-item{
  width:20%;
  padding:0 10px;
  box-sizing: border-box;
  cursor: pointer;
}

</style>